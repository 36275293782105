$container-padding: 50px;
$container-bg: #ffffff;
$sub-container-width: 60vw;
$sub-container-padding: 20px;

.create-chat-lead-modal.lc-modal {
    .MuiCard-root {
        @media (min-width: 790px) {
            width: 100%;
            min-width: 768px;
            max-width: 768px;
        }
    }
}

.container {
    display: flex;
    padding: $container-padding;
    justify-content: center;
    background-color: $container-bg;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.sub-container {
    width: $sub-container-width;
    padding: $sub-container-padding;
}
